.accordion {
  border-top: 1px solid $colorLine;
  border-bottom: 1px solid $colorLine;
  background: #fff;

  .accordion-content {
    padding: 8px 16px 20px 16px;
  }
  .accordion-header {
    position: relative;
    .btn {
      justify-content: flex-start;
      border-radius: 0 !important;
      width: 100%;
      font-weight: $regular;
      background: transparent !important;
      font-size: $fontSize;
      padding: 8px 50px 8px 16px;
      min-height: 50px;
      &:active {
        background: rgba($colorLine, 0.3) !important;
      }
      &:after {
        content: "\f3d1";
        font-family: "Ionicons";
        font-size: 18px;
        position: absolute;
        right: 16px;
        color: $colorLight;
        opacity: 0.6;
        line-height: 1em;
        height: 18px;
        top: 50%;
        margin-top: -9px;
        transform: rotate(-90deg);
      }
      &:before {
        content: "";
        display: block;
        height: 1px;
        background: transparent;
        position: absolute;
        left: 16px;
        bottom: 0;
        right: 0;
      }
      &.collapsed {
        &:after {
          transform: rotate(90deg);
        }
        &:before {
          background: $colorLine;
        }
      }
      .icon{
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .item:last-child {
    .accordion-header .btn:before {
        display: none;
    }
  }
}

.inset{
    .accordion{
        border-radius: $borderRadius;
        border: 1px solid $colorLine;
        .item{
            &:first-child{
                .btn{
                    border-radius: $borderRadius $borderRadius 0 0 !important;
                }
            }
            &:last-child{
                .btn{
                    border-radius: 0 0 $borderRadius $borderRadius !important;
                }
            }
        }
    }
}