.card {
  background: #FFFFFF;
  border-radius: $borderRadius;
  border: 1px solid $colorLine;
  .card-body{
      padding: 16px;
  }
  .card-title{
      color: $colorHeading;
      font-size: $fontSizeHeading;
      font-weight: $medium;
  }
  .card-subtitle{
      color: $colorHeading;
      font-weight: $regular;
      font-size: $fontSizeSub;
  }
  .listview{
    > li:first-child{
        .item{
            border-radius: $borderRadius $borderRadius 0 0;
        }
    }
    > li:last-child{
        .item{
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
  }
  .card-header{
      background: transparent;
      color: $colorHeading;
      font-weight: $medium;
      padding: 12px 16px;
      border-bottom: 1px solid $colorLine;
  }
  .card-footer{
      background: transparent;
      border-top: 1px solid $colorLine;
      color: $colorText;
      padding: 12px 16px;
  }
  .card-img-overlay{
      background: rgba(0,0,0 ,.5);
      .card-title{
          color: #FFF;
      }
      .card-text{
          color: rgba(255,255,255,.7)
      }
  }
  &.bg-primary,
  &.bg-secondary,
  &.bg-danger,
  &.bg-success,
  &.bg-warning,
  &.bg-info,
  &.bg-dark{
      border: 0;
      .card-title{
          color: #FFF;
      }
      .card-text{
        color: rgba(255,255,255,.7)
    }
    .card-header{
        color: #FFF;
        border-bottom: 1px solid rgba(255,255,255,.15);
    }
  }
  &.bg-light{
      background: #FFF;
  }
}
