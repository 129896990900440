.appHeader {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #FFFFFF;
  color: $colorHeading;
  border-bottom: 1px solid $colorLine;
  &.no-border{
    border: 0;
    box-shadow: none;
  }
  &.transparent{
    background: transparent;
  }
  .left,
  .right{
    height: 56px;
    display: flex;
    align-items: center;
    position: absolute;
    .icon{
      font-size: 26px;
    }
    .headerButton{
      min-width: 36px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: $colorPrimary;
      position: relative;
      .btn{
        font-size: 16px;
        height: 32px;
        padding: 0px 14px;
      }
      &:active{
        opacity: .6;
      }
    }
  }
  .left{
    left: 10px;
    top: 0;
  }
  .right{
    right: 10px;
    top: 0;
  }
  .pageTitle{
    font-size: 18px;
    font-weight: $medium;
    padding: 0 10px;
    .logo{
      max-height: 18px;
    }
  }
  &.text-light{
    color: #FFF;
    .headerButton{
      color: #FFF;
    }
  }
  &.bg-primary,
  &.bg-secondary,
  &.bg-success,
  &.bg-warning,
  &.bg-danger,
  &.bg-info,
  &.bg-light,
  &.bg-dark{
    border: 0;
  }
}


.extraHeader{
  position: fixed;
  left: 0;
  top: 50px;
  height: 50px;
  right: 0;
  width: 100%;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  background: #FFF;
  z-index: 1000;
  border-bottom: 1px solid $colorLine;
}