.form-button-group{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 16px;
    background: #FFF;
}
.form-links{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
}