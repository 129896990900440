////////////////////////////////////////////////////////////////
// Bootstrap 4
////////////////////////////////////////////////////////////////
@import "lib/bootstrap.min";

////////////////////////////////////////////////////////////////
// Ion Icons
////////////////////////////////////////////////////////////////
@import url('https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css');

////////////////////////////////////////////////////////////////
// Plugins
////////////////////////////////////////////////////////////////
// Owl Carousel
@import "plugin/owl-carousel/owl.carousel.min";
@import "plugin/owl-carousel/owl.theme.default";

////////////////////////////////////////////////////////////////
// Template Settings
////////////////////////////////////////////////////////////////
// Font Settings
@import 'font';
// Theme Variables
@import 'variables';
// Dark Mode
// @import 'darkmode';

////////////////////////////////////////////////////////////////
// Layout
////////////////////////////////////////////////////////////////
// Body - General Settings
@import 'body';
// Header
@import 'layout/header';
// Bottom Menu
@import 'layout/bottomMenu';
// Content
@import 'layout/content';
// Sidebar
@import 'layout/sidebar';
// Footer
@import 'layout/footer';

////////////////////////////////////////////////////////////////
// UI Kit
////////////////////////////////////////////////////////////////
@import 'ui';

////////////////////////////////////////////////////////////////
// Application styles (this project only)
////////////////////////////////////////////////////////////////
@import'app';