////////////////////////////////////////////////////
// Colors
////////////////////////////////////////////////////
$colorPrimary : #4984d2;
$colorSecondary : #8A8A8A;
$colorSuccess: #27ae60; //#467A03;
$colorDanger : #d44545;
$colorWarning : #FC672F;
$colorInfo : #2FCBFC;
$colorDark : #2c2c2c;
$colorWhite: #ffffff;

$colorLine : rgb(223, 228, 231);

$colorHeading : #2c2c2c;
$colorText : #73767A;
$colorLight : #bdc3c7; //#A9ABAD;

////////////////////////////////////////////////////
// Border Radius
////////////////////////////////////////////////////
$borderRadius: 6px;

$borderStatusWidth : 6px;
