#loader{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}