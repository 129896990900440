.dropdown,
.dropup{
  .dropdown-menu {
    border: 1px solid $colorLine;
    min-width: 120px;
    padding: 8px 0;
    border-radius: $borderRadius;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    .dropdown-item {
      padding: 9px 16px;
      line-height: 1.2em;
      color: $colorHeading;
      font-size: $fontSize;
      &:hover,
      &:active {
        background: rgba($colorLine, .5);
        color: $colorHeading;
      }
      .dropdown-divider {
        border-top: 1px solid $colorLine;
      }
    }
    .dropdown-header{
      padding: 9px 16px;
      color: $colorText;
      font-weight: $regular;
      font-size: $fontSizeCaption;
    }
    .text{
      padding: 9px 16px;
      color: $colorText;
    }
    .icon{
      font-size: $fontSizeHeading;
      margin-right: 4px;
      width: 20px;
      margin-bottom: -4px;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      display: inline-flex;
      color: $colorHeading
    }
  }
}
.appHeader{
  .dropdown-menu{
    margin-top: -10px;
  }
}

.dropdown-toggle{
  &:after{
    border-radius: 4px;
    margin-left: 7px;
  }
}