@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

body {
  //font-family: 'Open Sans', sans-serif;
  user-select: auto;
  -moz-user-select: auto;
  -webkit-user-select: auto;

}

/* Position of clear input icon in login/register */
.form-group .clear-input {
  right: 0;
}

.form-group .label {
  font-size: inherit;
}

.bg-primary a, .bg-dark a, .bg-secondary a {
  color: $colorWhite;
}

.bg-light {
  background-color: #f9fafc !important;
}

.task-primary {
  border-left: $borderStatusWidth solid $colorPrimary;
}
.task-secondary {
  border-left: $borderStatusWidth solid $colorSecondary;
}
.task-warning {
  border-left: $borderStatusWidth solid $colorWarning;
}
.task-danger {
  border-left: $borderStatusWidth solid $colorDanger;
}
.task-success {
  border-left: $borderStatusWidth solid $colorSuccess;
}
.task-dark {
  border-left: $borderStatusWidth solid $colorDark;
}

.task-warning span {
  color: $colorWarning;
}
.task-danger span {
  color: $colorDanger;
}



table.calendar {
  width: 100%;
}
table.calendar th {
  text-align: center;
}
table.calendar td {
  text-align: center;
}
table.calendar td.today {
  background-color: $colorPrimary;
  color: $colorWhite;
  font-weight: bold;
}

/* Remove top and bottom border from listview element*/
.listview {
  border: none;
}

/* Updated listview for file manager */
.files-listview a.item {
  padding-left: 0;
}
.files-listview .icon-box {
  justify-content: left;
}
.files-listview>li:after {
  left: 50px!important;
}

.listview div.iconwrapper {
  width: 30px;
}

/* Form labels slightly lighter */
.form-group .label {
  color: $colorText;
}

.listview a {
  color: $colorHeading;
}
.listview a:hover {
  color: $colorText;
}

.profileBox .image-wrapper {
  margin-right: 0;
}

.appHeader .left .headerButton, .appHeader .right .headerButton {
  padding-right: 0 !important;
}

.descriptionwrapper p {
  margin-bottom: 0 !important;
}

.accordion .item .fa-exclamation-circle,
.link-listview .fa-exclamation-circle {
  margin-right: 35px;
  height: 50px;
  color: $colorSecondary;
}
