.carousel-slider{
    text-align: center;
}
.carousel-button-footer{
    padding: 16px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
}