.modal.action-sheet {
  z-index: 200000;
  .modal-dialog {
    padding: 0;
    margin: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    min-width: 100%;
    z-index: 12000;
    transform: translate(0, 100%);
  }
  &.show .modal-dialog {
    transform: translate(0, 0);
  }
  .modal-content {
    box-shadow: none;
    border: 0;
    border-radius: 0;
    .action-sheet-content{
        padding: 20px 16px;
        max-height: 460px;
        overflow: auto;
    }
    .modal-header{
        display: block;
        padding: 0 20px;
        .modal-title{
            padding: 0;
            margin: 0;
            text-align: center;
            display: block;
            font-size: $fontSizeCaption;
            padding: 10px 0;
            color: $colorHeading;
            font-weight: $medium;
        }
    }
    .modal-body{
        padding: 0;
    }
  }
}

.action-button-list{
    padding: 0;
    margin: 0;
    > li{
        display: block;
        .btn{
            padding: 0;
            display: flex;
            width: 100%;
            min-height: 50px;
            font-size: $fontSize;
            color: $colorHeading;
            line-height: 1.1em;
            justify-content: space-between;
            font-weight: $regular;
            border-radius: 0 !important;
            padding: 10px 16px;
            &:active,
            &.active{
                background: rgba($colorLine, 0.3);
            }
            &.text-primary{
                color: $colorPrimary !important;
            }
            &.text-secondary{
                color: $colorSecondary !important;
            }
            &.text-danger{
                color: $colorDanger !important;
            }
            &.text-success{
                color: $colorSuccess !important;
            }
            &.text-warning{
                color: $colorWarning !important;
            }
            &.text-info{
                color: $colorInfo !important;
            }
            &.text-light{
                color: #FFF !important;
            }
            &.text-dark{
                color: $colorHeading !important;
            }
            > span{
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            .icon{
                width: 32px !important;
                height: 32px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                margin-bottom: -3px;
                margin-left: -5px;
                font-size: 24px;
                line-height: 1em;
            }
        }
        &.action-divider{
            height: 1px;
            background: $colorLine;
        }
    }
}

.action-sheet.inset{
    .modal-dialog{
        padding: 16px;
        .modal-content{
            border-radius: 16px;
        }
    }
    .action-button-list{
        > li:last-child{
            .btn{
                border-radius: 0 0 16px 16px !important;
            }
        }
    }
}