
body{
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: 1.55rem;
  letter-spacing: 0.004em;
  color: $colorText;
  background: #F9FAFC;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
a{
  transition: .2s all;
  color: $colorPrimary;
  &:hover,
  &:active,
  &:focus{
    color: $colorPrimary;
    text-decoration: none;
  }
}